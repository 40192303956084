/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const NAVY = "#051F3E";
const GOLD = "#C8B77C";

const donateFontStyles = ({ backgroundColor, color }) => css`
  background-color: ${backgroundColor};
  color: ${color};
  padding: 0 8px;
  border-radius: 4px;
  font-family: Evogria;
  font-size: 36px;
  flex-wrap: wrap;
  margin: 8px;
  text-decoration: none;
  @media (min-width: 769px) {
    margin: 36px 4px;
  }
`;

function DonationBar() {
  return (
    <div
      css={css`
        background-color: ${NAVY};
        margin-top: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        padding: 8px 0 12px;
        @media (min-width: 431px) {
            margin-top: 80px;
            padding-bottom: 0;
            padding-top: 0;
        }
        
      `}
    >
      <p css={donateFontStyles({ color: "white" })}>DONATE TO SAM GRECO</p>
      <div css={css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      `}>
      <a href="https://secure.winred.com/sam-greco-for-florida/donate-today/donate?amount=50" css={donateFontStyles({ backgroundColor: "#404472", color: GOLD })}>
        $50
      </a>
      <a href="https://secure.winred.com/sam-greco-for-florida/donate-today/donate?amount=100" css={donateFontStyles({ backgroundColor: "#404472", color: GOLD })}>
        $100
      </a>
      <a href="https://secure.winred.com/sam-greco-for-florida/donate-today/donate?amount=250" css={donateFontStyles({ backgroundColor: "#404472", color: GOLD })}>
        $250
      </a>
      <a href="https://secure.winred.com/sam-greco-for-florida/donate-today/donate?amount=500" css={donateFontStyles({ backgroundColor: "#404472", color: GOLD })}>
        $500
      </a>
      <a href="https://secure.winred.com/sam-greco-for-florida/donate-today" css={donateFontStyles({ backgroundColor: "#404472", color: GOLD })}>
        OTHER
      </a>
      </div>
    </div>
  );
}

export default DonationBar;
