/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import sam3 from './assets/sam-3.jpeg';

import Container from "./Container";

function SamPinning() {
  return (
    <Container width={960}>
      <div
        css={css`
        margin-top: 40px;
        @media (min-width: 431px) {
          margin-top: 80px;
        }
        `}
      >
        <img alt="Sam receiving a Naval pin from his wife Elanda" css={css`width: 100%;`} src={sam3} />
      </div>
    </Container>
  );
}

export default SamPinning;
