/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Container from "./Container";
import sam2 from "./assets/sam-2.jpeg";

const NAVY = "#051F3E";

function AboutSam() {
  return (
    <Container width={1080}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-top: 40px;

          @media (min-width: 431px) {
            margin-top: 80px;
          }
          @media (min-width: 769px) {
            flex-direction: row;
          }
        `}
      >
        <img
          css={css`
            @media (min-width: 769px) {
              width: 50%;
            }
          `}
          alt="Sam with his wife Elanda"
          src={sam2}
        />
        <div>
          <h2
            id="about-sam"
            css={css`
              color: ${NAVY};
              margin-left: 12px;
              font-family: Evogria;
              font-size: 36px;
              margin-top: 36px;
              @media (min-width: 769px) {
                width: 50%;
                margin-left: 36px;
              }
            `}
          >
            Meet Sam
          </h2>
          <p
            css={css`
              font-family: Poppins;
              margin-left: 16px;
              pading-right: 4px;
              @media (min-width: 769px) {
                margin-left: 48px;
              }
            `}
          >
            Sam Greco is a conservative Republican candidate for the Florida
            House of Representatives District 19. He served his country as an
            active duty JAG officer in the United States Navy from 2019-2024,
            and was most recently based at Naval Station Mayport in
            Jacksonville, FL. During his time on active duty, Sam had the
            opportunity to provide legal services in a variety of environments,
            including underway aboard the USS Gerald R. Ford and USS West
            Virginia. Sam continues to serve in the United States Navy Reserves
            today.
          </p>
          <p
            css={css`
              font-family: Poppins;
              margin-left: 16px;
              padding-right: 4px;
              @media (min-width: 769px) {
                margin-left: 48px;
              }
            `}
          >
            Sam married his wife Elanda in 2023. As a young child, Elanda and
            her family legally immigrated to the United States following the
            fall of communism in Europe, where they witnessed destructive
            socialist policies first-hand. Sam's family has lived in Florida for
            nearly 100 years. His great-grandfather ran the barbershop at Coral
            Gables’ Biltmore Hotel, where Sam and Elanda got married. Sam’s
            maternal grandfather was a landowner in Flagler County, and his
            paternal grandfather graduated with one of the first post-war
            classes from the University of Miami.
          </p>
          <p
            css={css`
              font-family: Poppins;
              margin-bottom: 0;
              margin-left: 16px;
              padding-right: 4px;
              @media (min-width: 769px) {
                margin-left: 48px;
              }
            `}
          >
            Sam is blessed to live and work in a state where the American Dream
            remains alive, and he’s running for the Florida House of
            Representatives to fight back against the liberals who want to kill
            that dream and turn Florida into California. Sam is pro-life, pro
            Second Amendment, and pro freedom. He’s a conservative fighter who
            believes that Northeast Florida is the best place in America to
            raise a family, start a business, and buy a home, and he wants to
            keep it that way. At this critical time, the First Coast needs a
            military officer like Sam Greco to move the region forward and
            defeat the radical left. In Tallahassee, Sam will continue his
            mission of service, deliver for Flagler and St. Johns Counties, and
            keep Florida free.
          </p>
        </div>
      </div>
    </Container>
  );
}

export default AboutSam;
