/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Container from "./Container";
import SamNavyForm from "./SamNavyForm";
import sam1 from './assets/sam-1-bright.jpeg';

function SamFormAndPhoto() {
  return (
    <Container width={940}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          @media (min-width: 431px) {
            display: flex;
            flex-direction: row;
          }
        `}
      >
        <SamNavyForm />
        <img
          alt="Sam Greco on Navy ship"
          css={css`
          @media (min-width: 431px) {
            width: 50%;
          }
          `}
          src={sam1}
        />
      </div>
    </Container>
  );
}

export default SamFormAndPhoto;
