/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import logo from "./assets/Greco-LogoFINAL_bluev2.png";
import fb from "./assets/Facebook_Logo_Secondary.png";
import twitter from './assets/logo-white.png';
import instagram from './assets/Instagram_Glyph_White.png';


const NAVY = "#051F3E";
const GOLD = "#C8B77C";

function Footer() {
  return (
    <div
      css={css`
        background-color: ${NAVY};
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width: 431px) {
            margin-top: 80px;
          }
      `}
    >
      <div
        css={css`
          max-width: 470px;
        `}
      >
        <img
          alt="Sam Greco House District 19 logo"
          css={css`
            margin-top: 8px;
            width: 100%;
          `}
          src={logo}
        />
      </div>
      <p css={css`
      color: ${GOLD};
      font-family: Evogria;
      font-size: 32px;
      margin: 0 0 16px 0;`}>CONNECT:</p>
      <div css={css`
      display: flex;
      flex-direction: row;
      margin-bottom: 24px`}>
        <a href="https://www.facebook.com/SamGrecoforFlorida"><img alt="Sam Greco for Florida Facebook link" css={css`max-width: 32px; margin-right: 12px;`} src={fb}/></a>
        <a href="https://twitter.com/SamGrecoFL"><img alt="Sam Greco for Florida Twitter link" css={css`max-width: 32px; margin-right: 12px;`} src={twitter}/></a>
        <a href="https://www.instagram.com/samgrecoFL"><img alt="Sam Greco for Florida Instagram link" css={css`max-width: 32px`} src={instagram}/></a>
      </div>
      <p css={css`
      color: ${GOLD};
      font-family: Evogria;
      font-size: 32px;
      margin: 0 0 16px 0;`}>CONTACT US:</p>
        <p
        css={css`
          color: white;
          font-family: Poppins;
          font-size: 16px;
          text-align: center;
          margin: 0;
        `}
      >
        info@samgrecoforflorida.com
      </p>
      <p
        css={css`
          color: white;
          font-family: Poppins;
          font-size: 16px;
          text-align: center;
          margin: 0;
        `}
      >
        PO Box: 840134
      </p>
      <p
        css={css`
          color: white;
          font-family: Poppins;
          font-size: 16px;
          text-align: center;
          margin: 0 0 24px 0;
        `}
      >
        St. Augustine, FL 32080
      </p>
      <p
        css={css`
          color: white;
          font-family: Poppins;
          font-size: 16px;
          border-radius: 4px;
          border-color: white;
          border-style: solid;
          padding: 8px;
          text-align: center;
          margin: 0 8px;
        `}
      >
        PAID BY SAM GRECO, REPUBLICAN, FOR STATE HOUSE DISTRICT 19
      </p>
      <p
        css={css`
          color: white;
          font-family: Poppins;
          font-size: 12px;
          padding: 8px;
          margin-bottom: 40px;
          text-align: center;
        `}
      >
        Use of Sam Greco's military rank, job titles, and photographs in uniform does not imply endorsement by the Department of the Navy or the Department of Defense.
      </p>
    </div>
  );
}

export default Footer;
