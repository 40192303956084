/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { css } from "@emotion/react";
import { useForm } from "react-hook-form";
import logo from "./assets/Greco-LogoFINAL_bluev2.png";

const NAVY = "#051F3E";
const GOLD = "#C8B77C";

const inputStyles = css`
  height: 40px;
  width: 80%;
  margin-bottom: 8px;
  box-sizing: border-box;
  border-radius: 4px;
`;

const errorStyles = css`
  font-family: Poppins;
  font-size: 12px;
  color: ${GOLD};
  margin: 0 0 4px 0;
`;

const buttonStyles = css`
  background-color: white;
  border-color: #c3c0c0;
  border-radius: 4px;
  height: 40px;
  width: 80%;
  color: ${NAVY};
  font-family: Evogria;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-top: 8px;
`;

function SamNavyForm() {
  const [showError, setShowError] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    const formData = new FormData();

    // Append form data to FormData object
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbzEVbOXjTWsUCCb78NOzJy26zVk387_ZC0_4PlrJwW1Gu9DGiOUEQPb7L1juf6nv-8hBA/exec",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        setShowForm(false);
        reset();
      } else {
        setShowError(true);
      }
    } catch (error) {
      setShowError(true);
      console.error("Error:", error);
    }
  };

  return (
    <div
      css={css`
        background-color: ${NAVY};
        width: 100%;
        @media (min-width: 431px) {
          width: 50%;
        }
      `}
    >
      <img
        alt="Sam Greco House District 19 logo"
        css={css`
          width: 100%;
        `}
        src={logo}
      />
      <div
        css={css`
          color: ${GOLD};
          font-family: Evogria;
          font-size: 18px;
          font-weight: 700;
          text-align: center;
          padding-bottom: 32px;
        `}
      >
        {showForm ? (
          <>
            <p>Sign up to join the team</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                css={inputStyles}
                {...register("First", {
                  required: "First Name is required",
                  pattern: {
                    value: /^[A-Za-z'-]+$/,
                    message: "Entered value does not match first name format",
                  },
                })}
                placeholder="First Name"
                type="text"
              />
              {errors.First && <p css={errorStyles}>{errors.First.message}</p>}
              <input
                css={inputStyles}
                {...register("Last", {
                  required: "Last Name is required",
                  pattern: {
                    value: /^[A-Za-z'-]+$/,
                    message: "Entered value does not match last name format",
                  },
                })}
                placeholder="Last Name"
                type="text"
              />
              {errors.Last && <p css={errorStyles}>{errors.Last.message}</p>}
              <input
                css={inputStyles}
                {...register("Email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Entered value does not match email format",
                  },
                })}
                placeholder="Email"
                type="email"
              />
              {errors.Email && <p css={errorStyles}>{errors.Email.message}</p>}
              <input
                type="tel"
                css={inputStyles}
                {...register("Phone", {
                  required: "Phone Number is required",
                  pattern: {
                    value: /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/,
                    message: "Entered value does not match phone number format",
                  },
                })}
                placeholder="Phone Number"
              />
              {errors.Phone && <p css={errorStyles}>{errors.Phone.message}</p>}
              <button css={buttonStyles} type="submit">
                JOIN
              </button>
            </form>
            {showError ? <p css={css`text-align: center; margin: 12px 24px;`}>There was an error submitting the form. Please try again.</p> : null}
          </>
        ) : (
          <p>Thank you for signing up to be on Team Greco!</p>
        )}
        <a href="https://secure.winred.com/sam-greco-for-florida/donate-today">
          <button css={buttonStyles}>DONATE</button>
        </a>
      </div>
    </div>
  );
}

export default SamNavyForm;
