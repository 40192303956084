/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

function Container({ children, width }) {
  return (
    <div
      css={css`
      width: 100%;
      @media (min-width: 769px) {
        max-width: ${width}px;
        margin-left: auto;
        margin-right: auto;
      }
      `}
    >
      {children}
    </div>
  );
}

export default Container;
