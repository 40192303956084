/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const NAVY = "#051F3E";

const mottoFontStyles = ({ backgroundColor, color }) => css`
  background-color: ${backgroundColor};
  color: ${color};
  font-family: Evogria;
  font-size: 36px;
  margin: 0;
`;

function Motto() {
  return (
    <div
      css={css`
        background-color: ${NAVY};
        margin-top: 40px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 16px 0;
        @media (min-width: 431px) {
            height: 120px;
            margin-top: 80px;
            padding: 0;
        }
      `}
    >
      <p css={mottoFontStyles({ color: "white" })}>Naval Prosecutor. Conservative Fighter.</p>
    </div>
  );
}

export default Motto;
