/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const NAVY = "#051F3E";
const GOLD = "#C8B77C";

const tabStyles = ({ backgroundColor, color }) => css`
  background-color: ${backgroundColor};
  color: ${color};
  font-size: 18px;
  font-family: Evogria;
  font-weight: 900;
  margin-left: 24px;
  padding: 8px 16px;
  text-decoration: none;
`;

function Navbar() {
  return (
    <div
      css={css`
        display: flex;
        height: 80px;
        top: 0;
        padding-right: 32px;
      `}
    >
      <div
        css={css`
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 100%;
          @media (min-width: 431px) {
            justify-content: flex-end;
          }
        `}
      >
        <a href="#about-sam" css={tabStyles({ color: NAVY })}>MEET SAM</a>
        <a href="https://secure.winred.com/sam-greco-for-florida/donate-today" css={tabStyles({ backgroundColor: NAVY, color: GOLD })}>
          DONATE
        </a>
      </div>
    </div>
  );
}

export default Navbar;
