import "./App.css";
import { Helmet } from 'react-helmet';
import Navbar from "./Navbar";
import SamFormAndPhoto from "./SamFormAndPhoto";
import AboutSam from "./AboutSam";
import DonationBar from "./DonationBar";
import SamPinning from "./SamPinning";
import Footer from "./Footer";
import Motto from "./Motto";
import icon from './assets/favicon.jpeg'

function App() {
  return (
    <>
    <Helmet><link rel="icon" href={icon} sizes="16x16" /><link rel="apple-touch-icon" sizes="16x16" href={icon} /><title>Sam Greco for House District 19</title></Helmet>
      <Navbar />
      <SamFormAndPhoto/>
      <DonationBar/>
      <AboutSam/>
      <Motto/>
      <SamPinning/>
      <Footer/>
    </>
  );
}

export default App;
